import React, { ChangeEvent, useCallback, useEffect } from 'react';

interface Step1Props {
  formData: {
    nombre: string;
    apellidos: string;
    dni: string;
    email: string;
    telefono: string;
    direccion: string;
    municipio: string;
    fechaNacimiento: string;
    observaciones: string;
    aceptaImagenes: boolean;
    tipo: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
}

const Step1: React.FC<Step1Props> = ({ formData, handleChange }) => {

  useEffect(() => {
    formData.tipo = 'alumno'
  }, [])

  const handleChecked = () => {
    formData.aceptaImagenes = !formData.aceptaImagenes
  }

  return (
    <div>
      <div className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3 mb-4" role="alert">
        <p className="font-bold">Formulario de matriculación para el curso 2024/2025 del Aula Municipal de Música de Mairena del Alcor</p>
        <p className="text-sm">- Si surgiese cualquier duda durante el proceso de matriculación ponte en contacto con nosotros a través de <a className='font-bold' href='mailto:aula@municipaldemairena.com'>aula@municipaldemairena.com</a>, o bien a través del teléfono <br /> <a className='font-bold' href='tel:+34644710375'>644 710 375</a>.</p>
        <p className="text-sm">- El coste de la matrícula es de <b>12€</b>.</p>
      </div>
      <h2 className="text-2xl font-bold mb-4">Paso 1: Datos personales del alumno</h2>
      <div className='space-y-2 pb-2'>
        <label className="block text-sm font-medium text-gray-700">Tipo<span className='text-red-500'>*</span></label>
        <select
          name="tipo"
          value={formData.tipo}
          onChange={handleChange}
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        >
          <option value="alumno">Alumno</option>
          <option value="banda">Músico de la Banda</option>
        </select>
        <p className="mt-2 text-xs text-blue-500 dark:text-green-400">Selecciona en este campo la opción “Músico de la Bandaˮ <span className="font-medium">sólo si ya perteneces a la Banda Municipal de Música de Mairena del Alcor</span> (NO la Banda Juvenil). En caso contrario, seleccionar la opción “Alumnoˮ.</p>
      </div>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Nombre<span className='text-red-500'>*</span></label>
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Apellidos<span className='text-red-500'>*</span></label>
          <input
            type="text"
            name="apellidos"
            value={formData.apellidos}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">DNI<span className='text-red-500'>*</span></label>
          <input
            type="text"
            name="dni"
            value={formData.dni}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            pattern="^[0-9]{8}[A-Za-z]$"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Email<span className='text-red-500'>*</span></label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
          <p className="mt-2 text-xs text-blue-500 dark:text-green-400">Donde recibirá todas las notificaciones así como la gestión de posibles incidencias.</p>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Teléfono de contacto<span className='text-red-500'>*</span></label>
          <input
            type="tel"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Dirección<span className='text-red-500'>*</span></label>
          <input
            type="text"
            name="direccion"
            value={formData.direccion}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Municipio<span className='text-red-500'>*</span></label>
          <input
            type="text"
            name="municipio"
            value={formData.municipio}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Fecha de nacimiento<span className='text-red-500'>*</span></label>
          <input
            type="date"
            name="fechaNacimiento"
            value={formData.fechaNacimiento}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Información adicional</label>
          <input
            type="text"
            name="observaciones"
            value={formData.observaciones}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
          <p className="mt-2 text-xs text-blue-500 dark:text-green-400">Si el alumno presenta alguna <b>alergia, intolerancia</b> o tiene alguna <b>dificultad de aprendizaje</b> cuéntanoslo en el siguiente recuadro.</p>
        </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            name="aceptaImagenes"
            className="form-checkbox h-5 w-5 text-blue-500"
            onChange={handleChecked}
          />
          <label className="ml-2 block text-sm text-gray-700">
            Autorizo a la <b>Asociación Cultural de Música de Mairena del Alcor</b> la obtencion de imágenes del alumno en sus actividades programadas, bien individuales o colectivas en sus instalaciones y fuera de ellas con la finalidad de difundirla entre alumnos, familiares, así como en las redes sociales de la Asociación.
          </label>
        </div>
      </div>
    </div>
  );
};

export default Step1;
