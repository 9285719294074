import React, { useState } from 'react';
import Step1 from '../components/Registration/Step1';
import Step2 from '../components/Registration/Step2';
import Step3 from '../components/Registration/Step3';

const MultiStepForm: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    dni: '',
    telefono: '',
    direccion: '',
    municipio: '',
    fechaNacimiento: '',
    observaciones: '',
    clase: '',
    nivel: '',
    horario: '',
    instrumento: '',
    aceptaImagenes: false,
    duracion: '',
    pricingId: '',
    producto: [],
    tipo: '',
    productoId: 0,
    subscriptionPrice: 0
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isStep1Valid = () => {
    const dniValidation = /^[0-9]{8}[A-Za-z]$/
    return (
      formData.tipo !== '' &&
      formData.nombre !== '' &&
      formData.apellidos !== '' &&
      dniValidation.test(formData.dni) &&
      formData.email !== '' &&
      formData.telefono !== '' &&
      formData.direccion !== '' &&
      formData.municipio !== '' &&
      formData.fechaNacimiento !== ''
    );
  };

  const isStep2Valid = () => {
    return (
      formData.clase !== '' ||
      (formData.nivel === 'lenguaje_musical' && formData.horario !== '' && formData.instrumento !== '' && formData.duracion !== '') ||
      (formData.nivel === 'solo_instrumento' && formData.instrumento !== '' && formData.duracion !== '') 
    );
  };

  const handleNext = () => {
    if (step === 1 && isStep1Valid()) {
      setStep(prevStep => prevStep + 1);
    } else if (step === 2 && isStep2Valid()) {
      setStep(prevStep => prevStep + 1);
    }
  };

  const handleBack = () => {
    setStep(prevStep => prevStep - 1);
  };

  return (
    <div className="max-w-2xl mx-auto p-4 pb-12">
      {step === 1 && <Step1 formData={formData} handleChange={handleChange} />}
      {step === 2 && <Step2 formData={formData} handleChange={handleChange} setFormData={setFormData} />}
      {step === 3 && <Step3 formData={formData} />}
      
      <div className="flex justify-between mt-4">
        {step > 1 && (
          <button
            onClick={handleBack}
            className="py-2 px-4 bg-gray-300 text-gray-800 font-semibold rounded-md hover:bg-gray-400"
          >
            Atrás
          </button>
        )}
        <div></div>
        {step < 3 && (
          <button
            onClick={handleNext}
            disabled={(step === 1 && !isStep1Valid()) || (step === 2 && !isStep2Valid())}
            className={`py-2 right-0 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 ${
              (step === 1 && !isStep1Valid()) || (step === 2 && !isStep2Valid()) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Siguiente
          </button>
        )}
      </div>
    </div>
  );
};

export default MultiStepForm;
