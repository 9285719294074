import { NavLink} from 'react-router-dom'
import { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/16/solid';
import { Dialog, DialogPanel } from '@headlessui/react';

export default function NavBar() {

	const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

	const navigation = [
		{ name: 'Inicio', href: '/' },
		{ name: 'Tarifas', href: '/tarifas' },
		{ name: 'Horarios', href: '/horarios' },
		{ name: 'Formulario de matriculación', href: '/matriculacion' },
	]

  return (
		<div className="bg-white z-50 mb-20">
			<header className="fixed inset-x-0 top-0 z-50 bg-white">
				<nav
					className="flex items-center justify-between p-4 lg:px-6"
					aria-label="Global">
					<div className="flex lg:flex-1">
						<NavLink
							to="/"
							className="-m-1.5 p-1.5">
							<span className="sr-only">BMM</span>
							<img
								className="h-12 w-auto"
								src={'https://municipaldemairena.com/wp-content/uploads/2024/06/HorizontalColorSinFondoAmpliado.png'}
								alt=""
							/>
						</NavLink>
					</div>
					<div className="flex lg:hidden">
						<button
							type="button"
							className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
							onClick={() => setMobileMenuOpen(true)}>
							<span className="sr-only">Abrir menú</span>
							<Bars3Icon
								className="h-6 w-6 dark:text-white"
								aria-hidden="true"
							/>
						</button>
					</div>
					<div className="hidden lg:flex lg:gap-x-12">
						{navigation.map((item) => (
							<NavLink
								key={item.name}
								to={item.href}
								className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
								{item.name}
							</NavLink>
						))}
					</div>
					<div className="hidden lg:flex lg:flex-1 lg:justify-end cursor-pointer">
						<div className="flex items-center">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
								<path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
							</svg>
							<a href="tel:644710375" className="flex items-center ml-2">
								644 71 03 75
							</a>
						</div>
					</div>
				</nav>
				<Dialog
					className="lg:hidden ease-linear"
					open={mobileMenuOpen}
					onClose={setMobileMenuOpen}>
					<div className="fixed inset-0 z-50" />
					<DialogPanel className="fixed bg-stone-300 inset-y-0 right-0 z-50 w-full h-96 overflow-y-auto bg-white dark:bg-slate-700 rounded-b-xl px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 ease-in-out duration-3000">
						<div className="flex items-center justify-between">
							<a
								href="/"
								className="-m-1.5 p-1.5">
								<span className="sr-only">Your Company</span>
								<img
									className="h-8 w-auto"
									src={"https://municipaldemairena.com/wp-content/uploads/2020/06/LogoAuladeM%C3%BAsicaColor.png"}
									alt=""
								/>
							</a>
							<button
								type="button"
								className="-m-2.5 rounded-md p-2.5 text-gray-700"
								onClick={() => setMobileMenuOpen(false)}>
								<span className="sr-only">Close menu</span>
								<XMarkIcon
									className="h-6 w-6 dark:text-white"
									aria-hidden="true"
								/>
							</button>
						</div>
						<div className="mt-6 flow-root">
							<div className="-my-6 divide-y divide-gray-500/10">
								<div className="space-y-2 py-6">
									{navigation.map((item) => (
										<a
											key={item.name}
											href={item.href}
											className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 ease-linear">
											{item.name}
										</a>
									))}
								</div>
								<div className="py-6">
									<div className="flex items-center">
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
											<path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
										</svg>
										<a href="tel:644710375" className="flex items-center ml-2">
											644 71 03 75
										</a>
									</div>
								</div>
							</div>
						</div>
					</DialogPanel>
				</Dialog>
			</header>
		</div>

	);
}
