import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./components/Navbar";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Matriculacion from "./pages/Matriculacion";
import Footer from "./components/Footer";
import { loadStripe } from '@stripe/stripe-js';
import { StripeProvider } from "./context/StripeContext";
import { Elements } from '@stripe/react-stripe-js'
import RegistrationSuccess from "./pages/RegistrationSuccess";
import Horarios from "./components/Horarios";

const stripePromise = loadStripe(`${process.env.REACT_APP_PK_STRIPE}`);

function App() {

  console.log(`${process.env.REACT_APP_PK_STRIPE}`);
  
  return (
    <BrowserRouter>
        <NavBar />
        <div className="flex flex-col min-h-screen">
          <div className="flex-grow">
          <StripeProvider>
            <Routes>
              <Route path="/" element={<Home />} key={1} />
              <Route path="/tarifas" element={<Pricing />} key={2} />
              <Route path="/horarios" element={<Horarios />} key={5} />
              <Route path="/matriculacion" element={<Elements stripe={stripePromise}><Matriculacion /></Elements>} key={3} />
              <Route path="/success" element={<RegistrationSuccess key={4} />} />
            </Routes>
          </StripeProvider>
          </div>
        </div>
        <Footer />
    </BrowserRouter>
  );
}

export default App;
