// StripeContext.tsx
import React, { createContext, useContext } from 'react';
import firebase, { functions } from '../database/firebase'

interface StripeContextType {
  handleSubmit: () => void; // Definición de la función para manejar el pago con Stripe
}

const StripeContext = createContext<StripeContextType | undefined>(undefined);

export const useStripeContext = () => {
  const context = useContext(StripeContext);
  if (!context) {
    throw new Error('useStripeContext debe ser usado dentro de un StripeProvider');
  }
  return context;
};

interface StripeProviderProps {
  children: React.ReactNode; // Definir explícitamente que se espera children como ReactNode
}

export const StripeProvider: React.FC<StripeProviderProps> = ({ children }) => {
  const handleSubmit = async() => {
    const createSubscription = functions.httpsCallable('createSubscriptionCall');
    const result = await createSubscription({email: 'g.lops.h1@gmail.com', paymentMethodId: 'pm_1MqLiJLkdIwHu7ixUEgbFdYF' })
  };

  const contextValue: StripeContextType = {
    handleSubmit,
  };

  return (
    <StripeContext.Provider value={contextValue}>
      {children}
    </StripeContext.Provider>
  );
};
