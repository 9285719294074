import React from 'react'

interface ScheduleItem {
  level: string;
  schedule: string;
}

interface ScheduleCategory {
  category: string;
  items: ScheduleItem[];
}

const schedules: ScheduleCategory[] = [
  {
    category: "Lenguaje Musical",
    items: [
      { level: 'Iniciación A', schedule: 'Lunes y miércoles de 16:00 a 17:00' },
      { level: 'Iniciación B', schedule: 'Martes y jueves de 16:00 a 17:00' },
      { level: '1º A', schedule: 'Lunes y miércoles de 17:00 a 18:00' },
      { level: '1º B', schedule: 'Martes y jueves de 17:00 a 18:00' },
      { level: '2º A', schedule: 'Lunes y miércoles de 18:00 a 19:00' },
      { level: '2º B', schedule: 'Martes y jueves de 18:00 a 19:00' },
      { level: '3º A', schedule: 'Lunes y miércoles de 19:00 a 20:00' },
      { level: '3º B', schedule: 'Martes y jueves de 19:00 a 20:00' },
      { level: '4º A', schedule: 'Lunes y miércoles de 20:00 a 21:00' },
      { level: '4º B', schedule: 'Martes y jueves de 20:00 a 21:00' },
    ],
  },
  {
    category: "Música y Movimiento",
    items: [
      { level: 'MM1 (3 años)', schedule: 'Viernes de 16:00 a 17:00' },
      { level: 'MM2 (4 años)', schedule: 'Viernes de 17:00 a 18:00' },
      { level: 'MM3 (5 años)', schedule: 'Viernes de 18:00 a 19:00' },
      { level: 'MM4 (6 años)', schedule: 'Viernes de 19:00 a 20:00' },
    ],
  }
];

export default function Horarios() {

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8">Horarios del Aula de Música</h1>
        {schedules.map(({ category, items }) => (
          <div key={category} className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">{category}</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <ul className="divide-y divide-gray-200">
                {items.map(({ level, schedule }) => (
                  <li key={level} className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div className="text-sm font-medium text-indigo-600 truncate">{level}</div>
                      <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                        <p>{schedule}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
