// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCH5HvXkjSnx6CHkrsww3DvsuT4sbGIBNY",
  authDomain: "aula-bmm.firebaseapp.com",
  projectId: "aula-bmm",
  storageBucket: "aula-bmm.appspot.com",
  messagingSenderId: "280398204147",
  appId: "1:280398204147:web:2a5f5eb9fb97b5d65e3b86"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const functions = app.functions();
export const firestore = app.firestore();

export default app;