import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Home() {

  useEffect(() => {
    localStorage.clear()
  })
  const stats = [
    { id: 1, name: 'Matriculaciones el pasado año', value: '+ de 200' },
    { id: 2, name: 'Aprobados en las pruebas de acceso el pasado año', value: '100%' },
    { id: 3, name: 'Titulados o con experiencia musical', value: '12 profesores' },
  ]
  return (
    <div>
      <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <div
          className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
            }}
          />
        </div>
        <div
          className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
            style={{
              clipPath:
                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
            }}
          />
        </div>
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
          <p className="text-sm leading-6 text-gray-900">
            <strong className="font-semibold">Matriculación 24/25</strong>
            <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
              <circle cx={1} cy={1} r={1} />
            </svg>
            Desde el 24 de junio. Mantente atento a nuestras redes sociales
          </p>
          {/* <a
            href="#"
            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Regístrate ahora <span aria-hidden="true">&rarr;</span>
          </a> */}
        </div>
        <div className="flex flex-1 justify-end">
        </div>
      </div>
      <div className="bg-gradient-to-r from-purple-500 to-indigo-600 py-16 px-6 text-white">
        <div className="max-w-4xl mx-auto text-center">
          <img className='drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]' src={'https://municipaldemairena.com/wp-content/uploads/2024/06/HorizontalColorSinFondoAmpliadoBlanco.png'} />
          <h1 className="text-4xl md:text-6xl font-bold mb-4">Únete a nuestro aula de música</h1>
          <p className="text-lg md:text-xl mb-8">Descubre tu pasión por la música con nuestros cursos personalizados.</p>
          <a href="#" className="bg-white text-indigo-600 hover:bg-indigo-600 hover:text-white py-3 px-8 rounded-full uppercase font-bold tracking-wide inline-block">Inscríbete a partir del 24 de junio</a>
        </div>
      </div>
      <div className="max-w-4xl mx-auto py-12 px-6">
        <h2 className="text-3xl font-bold mb-6 text-gray-800">Descubre nuestro aula de música</h2>
        <p className="text-lg mb-8">Haremos que ames la música como nosotros Te lo ponemos fácil para empezar a leer e interpretar música de todos los estilos.</p>
        <p className="text-lg mb-8">Para todas las edades, a partir de 3 años.</p>
        <p className="text-lg mb-8">Horarios flexibles y adaptados a los alumnos.</p>

        <div className="text-center flex space-x-4">
          <Link to="/tarifas" className="flex flex-row bg-indigo-600 w-min text-nowrap text-white py-3 px-8 rounded-full inline-block font-bold uppercase tracking-wide hover:bg-indigo-700">Tarifas <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 my-auto ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
          </svg>
          </Link>
          <Link to="/horarios" className="flex flex-row bg-indigo-600 w-min text-nowrap text-white py-3 px-8 rounded-full inline-block font-bold uppercase tracking-wide hover:bg-indigo-700">Horarios <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 my-auto ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
          </svg>
          </Link>
        </div>
      </div>

      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
            {stats.map((stat) => (
              <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
