import React, { useState, useEffect } from 'react';
import { duraciones, instrumentos, niveles, nivelesMym } from '../../assets/constants/Materias';

interface Step2Props {
  formData: {
    tipo: string;
    clase: string;
    nivel?: string;
    horario?: string;
    instrumento?: string;
    duracion?: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  setFormData: (data: any) => void;
}

const Step2: React.FC<Step2Props> = ({ formData, handleChange, setFormData }) => {
  const [nivelDropdownHabilitado, setNivelDropdownHabilitado] = useState(false);
  const [horarioDropdownHabilitado, setHorarioDropdownHabilitado] = useState(false);
  const [instrumentoDropdownHabilitado, setInstrumentoDropdownHabilitado] = useState(false);

  useEffect(() => {
    // Set initial state based on formData values
    if (formData.clase === 'musica_movimiento') {
      setNivelDropdownHabilitado(true);
      setHorarioDropdownHabilitado(false);
      setInstrumentoDropdownHabilitado(false);
    } else if (formData.clase === 'lenguaje_musical') {
      setNivelDropdownHabilitado(true);
      setHorarioDropdownHabilitado(true);
      setInstrumentoDropdownHabilitado(true);
    } else if (formData.clase === 'solo_instrumento') {
      setNivelDropdownHabilitado(false);
      setHorarioDropdownHabilitado(false);
      setInstrumentoDropdownHabilitado(true);
    }
  }, [formData.clase]);

  useEffect(() => {

    if (formData.tipo === 'banda') {
      formData.clase = 'solo_instrumento'
    }

    // Enable/disable horario dropdown based on nivel value
    if (formData.nivel && formData.clase !== 'musica_movimiento') {
      setHorarioDropdownHabilitado(true);
    } else {
      setHorarioDropdownHabilitado(false);
    }
  }, [formData.nivel, formData.clase]);

  const handleClaseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const claseSeleccionada = e.target.value;

    handleChange(e);

    setFormData({
      ...formData,
      clase: claseSeleccionada,
      nivel: '',
      horario: '',
      instrumento: '',
      duracion: ''
    });

    if (claseSeleccionada === 'musica_movimiento') {
      setNivelDropdownHabilitado(true);
      setHorarioDropdownHabilitado(false);
      setInstrumentoDropdownHabilitado(false);
    } else if (claseSeleccionada === 'lenguaje_musical') {
      setNivelDropdownHabilitado(true);
      setHorarioDropdownHabilitado(true);
      setInstrumentoDropdownHabilitado(true);
    } else if (claseSeleccionada === 'solo_instrumento') {
      setNivelDropdownHabilitado(false);
      setHorarioDropdownHabilitado(false);
      setInstrumentoDropdownHabilitado(true);
    }
  };

  const handleNivelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange(e);

    if (e.target.value !== '' && formData.clase !== 'musica_movimiento') {
      setHorarioDropdownHabilitado(true);
    } else {
      setHorarioDropdownHabilitado(false);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Paso 2: Modalidad de matrícula</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Clase</label>
          <select
            name="clase"
            value={formData.clase}
            onChange={handleClaseChange}
            disabled={formData.tipo === 'banda'}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md disabled:opacity-35"
            required
          >
            <option value="">Selecciona una opción</option>
            <option value="musica_movimiento">Música y Movimiento</option>
            <option value="lenguaje_musical">Lenguaje Musical + instrumento</option>
            <option value="solo_instrumento">Sólo Instrumento</option>
          </select>
        </div>

        {nivelDropdownHabilitado && (
          <div>
            <label className="block text-sm font-medium text-gray-700">Nivel</label>
            <select
              name="nivel"
              value={formData.nivel}
              onChange={handleNivelChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              required={formData.clase === 'lenguaje_musical'}
            >
              <option value="">Selecciona una opción</option>
              {niveles && formData.clase === 'lenguaje_musical' && (
                niveles.map((nivel) => (
                  <option key={nivel.value} value={nivel.value}>{nivel.label}</option>
                ))
              )}
              {nivelesMym && formData.clase === 'musica_movimiento' && (
                nivelesMym.map((nivel) => (
                  <option key={nivel.value} value={nivel.value}>{nivel.label}</option>
                ))
              )}
            </select>
          </div>
        )}

        {formData.clase === 'lenguaje_musical' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">Horario</label>
            <select
              name="horario"
              value={formData.horario}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              required={formData.clase === 'lenguaje_musical'}
            >
              <option value="">Selecciona una opción</option>
              <option value="lunes_miercoles">Lunes y Miércoles</option>
              <option value="martes_jueves">Martes y Jueves</option>
            </select>
          </div>
        )}

        {instrumentoDropdownHabilitado && (
          <div>
            <label className="block text-sm font-medium text-gray-700">Instrumento</label>
            <select
              name="instrumento"
              value={formData.instrumento}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              required={formData.clase === 'lenguaje_musical' || formData.clase === 'solo_instrumento'}
            >
              <option value="">Selecciona una opción</option>
              {instrumentos.map((instrumento) => (
                <option key={instrumento.value} value={instrumento.value}>{instrumento.label}</option>
              ))}
            </select>
          </div>
        )}
        {instrumentoDropdownHabilitado && (
          <div>
            <label className='block text-sm font-medium text-gray-700'>Duración</label>
            <select
              name='duracion'
              value={formData.duracion}
              onChange={handleChange}
              className='mt-1 block w-full p-2 border border-gray-300 rounded-md'
              required={formData.instrumento != null}
            >
              <option value="">Selecciona una opción</option>
              {formData.clase === 'lenguaje_musical' && duraciones.map((duracion) => {
                return <option value={duracion.value} key={duracion.value}>{duracion.label}</option>
              })}
              {formData.clase === 'solo_instrumento' && duraciones.filter((i) => {return i.value !== '20_minutos'}).map((duracion) => {
                return <option value={duracion.value} key={duracion.value}>{duracion.label}</option>
              })}
            </select>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step2;
