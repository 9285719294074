export const instrumentos = [
  { value: 'oboe', label: 'Oboe'},
  { value: 'flauta', label: 'Flauta travesera' },
  { value: 'clarinete', label: 'Clarinete' },
  { value: 'saxofon', label: 'Saxofón' },
  { value: 'trompeta', label: 'Trompeta' },
  { value: 'trombon', label: 'Trombón' },
  { value: 'trompa', label: 'Trompa' },
  { value: 'tuba', label: 'Tuba' },
  { value: 'percusion', label: 'Percusion' },
  { value: 'corneta', label: 'Corneta' },
  { value: 'piano', label: 'Piano' },
  { value: 'guitarra', label: 'Guitarra' },
  { value: 'violin', label: 'Violín' },
]

export const clases = [
  { value: 'musica_movimiento', label: 'Música y Movimiento', pricingId: 'prod_QHpzc0j7cxRxSc'},
  { value: 'lenguaje_musical', label: 'Lenguaje musical + intrumento'},
  { value: 'solo_instrumento', label: 'Solo instrumento'},
]

export const niveles = [
  { value: 'iniciacion', label: 'Iniciación: de 16:00 a 17:00'},
  { value: 'primero', label: '1º: de 17:00 a 18:00'},
  { value: 'segundo', label: '2º: de 18:00 a 19:00'},
  { value: 'tercero', label: '3º: de 19:00 a 20:00'},
  { value: 'cuarto', label: '4º: de 20:00 a 21:00'},
]
  
export const nivelesMym = [
  { value: 'mym1', label: 'Grupo 1: 16:00 a 17:00 - 3 años'},
  { value: 'mym2', label: 'Grupo 2: 17:00 a 18:00 - 4 años'},
  { value: 'mym3', label: 'Grupo 3: 18:00 a 19:00 - 5 años'},
  { value: 'mym4', label: 'Grupo 4: 19:00 a 20:00 - 6 años'},
]
  
export const horarios = [
  { value: 'lunes_miercoles', label: 'Lunes y miércoles' },
  { value: 'martes_jueves', label: 'Martes y jueves' },
  ]
  
export const duraciones = [
  { value: '20_minutos', label: '20 minutos de instrumento / semana: 35€ ' },
  { value: '30_minutos', label: '30 minutos de instrumento / semana: 45€' },
  { value: '60_minutos', label: '60 minutos de instrumento / semana: 75€' },
]

export const PRODUCTS = [
  {
    MUSICA_MOVIMIENTO: {
      devProductId: 'prod_QJjBR5yXDetzNO',
      productoId: 'prod_QHpzc0j7cxRxSc'
    }
  }
]

export const translate = (category: string, field: any) => {
  switch (category) {
    case 'clases':
        return clases.find((e) => e.value === field)?.label
    case 'instrumentos':
        return instrumentos.find((e) => e.value === field)?.label
    case 'niveles':
        return niveles.concat(nivelesMym).find((e) => e.value === field)?.label
    case 'horarios':
        return horarios.find((e) => e.value === field)?.label
    case 'duraciones':
        return duraciones.find((e) => e.value === field)?.label
    default:
      return
  }
}